import Goals from "../assets/projects/Goals.png";
import TaskManager from "../assets/projects/task-manager.png";

export const work = [
  {
    id: 1,
    title: "Task Management App",
    image: TaskManager,
    code: "https://github.com/VinaySaladi/task-manager",
    live: "https://vinay-saladi-task-manager.netlify.app",
  },
  {
    id: 2,
    title: "Goals App",
    image: Goals,
    code: "https://github.com/VinaySaladi/Goals-app",
    live: "",
  },
];

import React from "react";

const Home = () => {
  return (
    <div name="home" className="w-full h-screen bg-[#0a192f]">
      {/* Container */}
      <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
        <p className="text-pink-600">Hi, my name is</p>
        <h1 className="text-4xl sm:text-7xl font-bold text-[#ccd6f6]">
          Vinay Saladi
        </h1>
        <h2 className="text-4xl sm:text-7xl font-bold text-[#8892b0]">
          Front-End Developer.
        </h2>
        <p className="text-[#8892b0] py-4 max-w-[700px]">
          Designing and implementing the user interface (UI) of websites and
          applications using technologies such as HTML, CSS, and JavaScript.
          Collaborating with designers and back-end developers to ensure that
          the final product meets the requirements and specifications of the
          client.
        </p>
        {/* <div>
          <button className='text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-pink-600 hover:border-pink-600'>
            View Work
            <span className='group-hover:rotate-90 duration-300'>
              <HiArrowNarrowRight className='ml-3 ' />
            </span>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Home;
